import store from "@/store";
import router from "@/router"
export default class Helper {
    static clearToken() {
        store.commit("setAuthentication", "")
        store.commit("setAccessToken", "")
    }
    static setLoginDetails(data) {
        this.setLoginData(data);
        if (store.getters.authenticated == false) {
            router.push({ name: "Home" });
        } else {
            if(store.getters.loginSource ==='sell-property-btn'){
                router.push({ name: "Profile" })
            }else{
                router.push({ name: "Profile" })
            }
        }
    }
    static setLoginData(data) {
        store.commit("setAccessToken", data.access_token);
        const userData = {
            id: data.user.id,
            name: data.user.name,
            email: data.user.email,
            mobile_number: data.user.mobile_number,
            country: data.user.country,
            address: data.user.address,
            city: data.user.city,
            avatar: data.user.avatar,
            avatar_path: data.user.avatar_path,
        }
        store.commit("setUserData", userData)
    }
    static clearUserData(){
        const userData = {
            id: "",
            name: "",
            email: "",
            mobile_number: "",
            country: "",
            address: "",
            city: "",
            avatar: "",
            avatar_path: "",
        }
        store.commit("setUserData", userData)
        store.commit("setUserWishlist",0);
    }
    static logout() {
        this.clearToken();
        this.clearUserData();
    }
    static setPropertyData(data) {
        const reqData = {
            address: data.formRequest.address,
        }
        store.commit("setReqPropertyData", reqData)
    }
    /**
     * convert object params to url
     */
    static objToUrlParams(obj) {
        return Object.keys(obj).map(key => encodeURIComponent(key) + "=" + encodeURIComponent(obj[key] ? obj[key] : '')).join("&");
    }
    static showSpinner() {
        //Show loading. This is the only way to make it appear before the app is mounted.
        let spinnerWrapper = document.createElement('div');
        spinnerWrapper.className = 'spinner-wrapper';
        let spinner = document.createElement('div');
        spinner.className = 'spinner';
        spinnerWrapper.appendChild(spinner);
        let spinnerStyle = document.createElement('style');
       // spinnerStyle.textContent = ``;
        spinnerWrapper.appendChild(spinnerStyle);
        let appDiv = document.getElementById('app');
        appDiv.appendChild(spinnerWrapper);
    }
    /**
     * Hide spinner
     */
    static hideSpinner() {
        let div = document.getElementsByClassName('spinner-wrapper');
        if(div && div[0]){
          div[0].remove();
        }
    }
}